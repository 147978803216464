body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }


  @media(max-width: 1024px){
    body{
      font-size: 12px;
      zoom: 0.75;
      transform-origin: top left;
    }

  @media(min-width: 1025px) and (max-width: 1440px){
    body{
      zoom: 0.90;
    }
  }

  }